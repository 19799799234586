import { useEffect, useState } from 'react';
import MsDyn365, { ICoreContext } from '@msdyn365-commerce/core';

export const useIsMobile = (context: ICoreContext) => {
    type GridSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

    const [isMobile, setIsMobile] = useState(
        (context && context.request && context.request.device && context.request.device.Type === 'Mobile') || false
    );

    const updateIsMobile = () => {
        const viewport = getViewport();
        setIsMobile(viewport === 'xs' || viewport === 'sm');
    };

    const getViewport = (): GridSize => {
        // Always render in mobile viewport on a mobile device
        if (context.request && context.request.device && context.request.device.Type === 'Mobile') {
            return 'xs';
        }

        if (MsDyn365.isBrowser && window.innerWidth) {
            const gridSettings = context.request.gridSettings;
            if (gridSettings) {
                if (gridSettings.xs && window.innerWidth <= gridSettings.xs.w) {
                    return 'xs';
                } else if (gridSettings.sm && window.innerWidth <= gridSettings.sm.w) {
                    return 'sm';
                } else if (gridSettings.md && window.innerWidth <= gridSettings.md.w) {
                    return 'md';
                } else if (gridSettings.lg && window.innerWidth <= gridSettings.lg.w) {
                    return 'lg';
                }
                return 'xl';
            }
        }

        return 'xs';
    };

    useEffect(() => {
        if (!!window && typeof window !== 'undefined' && window.addEventListener) {
            updateIsMobile();
            window.addEventListener('resize', updateIsMobile);
        }
    }, []);

    return isMobile;
};
